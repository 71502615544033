export default [

  {
    key: 'requester',
    label: 'requester',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },

  {
    key: 'rol_name',
    label: 'Job name',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },

  {
    key: 'new_employee_quantity',
    label: 'Contracted',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },

  {
    key: 'salary',
    label: 'Salary range',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'shift',
    label: 'shift',
    visible: true,
    tdClass: 'tdClass',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },

  {
    key: 'status',
    label: 'status',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    thStyle: { width: '15%' },
    tdStyle: { width: '15%' },
  },

  {
    key: 'date_start',
    label: 'start date',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    thStyle: { width: '15%' },
    tdStyle: { width: '15 %' },
  },

  {
    key: 'actions',
    label: 'actions',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    thStyle: { width: '10%' },
    tdStyle: { width: '10%' },
  },
];
